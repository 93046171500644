<template>
    <div>
        <div class="tab-bar">
            <div
                v-for="item in routeViews"
                :key="item.routerName"
                @click="changeTabBar(item.routeName)"
                :class="{ active: item.routeName == $route.name }"
            >
                {{ item.name }}
            </div>
        </div>
        <router-view />
    </div>
</template>

<script>
export default {
    name: "meetingRoomManage",
    data() {
        return {
            routeViews: [
                { name: "订单", routeName: "meetingRoomOrder" },
                { name: "已退款", routeName: "refundMeetingRoom" },
                { name: "会议室管理", routeName: "meetingRoomManage" },
                { name: "设置", routeName: "meetingRoomConfig" },
            ],
        };
    },
    methods: {
        changeTabBar(routerName) {
            this.$router.push({ name: routerName });
        },
    },
};
</script>

<style scoped>
.tab-bar div {
    border-radius: 4px;
}
.tab-bar > div:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.tab-bar > div:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
</style>
